import SVG from "svg.js";
import spline from './Spline';
import random from './Random';
import CharacterStyles from './CharacterStyles';


class Character {
    constructor(_id, target = document.body, inheritStyles = null) {
      this.svg = SVG(target).viewbox(0, 0, 500, 500);
      this.svg.id(_id);
      this.centre = 250;

      this.style = new CharacterStyles(inheritStyles);
      this.setStyles();
      
      // Create Additional Styles
      this.generateStyles();
    }
  
    generateStyles() {
        this.fill = "hsl(" + this.style.baseHue + ", " + this.style.baseSaturation + "%, " + this.style.baseLightness + "%)";
    }

    setStyles() {

        if(this.style.gender === null) this.style.gender = random(0, 1, true);
        if(this.style.bodyWidth === null) this.style.bodyWidth = random(100, 150);
        if(this.style.bodyHeight  === null) this.style.bodyHeight = random(80, 120);
        if(this.style.mouthWidth === null) this.style.mouthWidth = this.style.bodyWidth * random(0.5, 0.7);
        if(this.style.mouthHeight === null) this.style.mouthHeight = this.style.bodyHeight * random(0.2, 0.5);

        // Colours
        if(this.style.baseHue===null) this.style.baseHue = random(0, 360);
        if(this.style.baseSaturation ===null) this.style.baseSaturation = random(60,80);
        if(this.style.baseLightness ===null) this.style.baseLightness = random(70,85);
        if(this.style.backgroundSaturation ===null)this.style.backgroundSaturation = random(75,85);
        if(this.style.backgroundLightness ===null)this.style.backgroundLightness  = random(88,94);


        var choiceArray = [0,1,2,3];
        if (this.style.mouthWidth < 60) {
            choiceArray = [0,1,3];
        }
         
        if(this.style.mouthSelector===null) this.style.mouthSelector = random(choiceArray);
        
    

        var accessoriesColourArray = [
            "#ed0cf4",
            "#3fa9f5",
            "#a32121",
            "#14145b"
        ]

        if (this.style.gender) {
            if(this.style.accessories===null)this.style.accessories = random(0,2,true);
            if(this.style.accessoriesColour===null)this.style.accessoriesColour = accessoriesColourArray[random(0,accessoriesColourArray.length-1,true)];
            if(this.style.accessoriesRotation===null)this.style.accessoriesRotation = random([-8,0,10]);
        }   


        switch(this.style.mouthSelector) {
            case 0 : 
                // Tongue Options
                if(this.style.chooseMouth===null) this.style.chooseMouth = 1;
                if(this.style.tongueStyle===null)  this.style.tongueStyle = random([0,1]); 

                if(this.style.tongueWidth===null) this.style.tongueWidth = this.style.mouthWidth * random(0.6, 0.8)
                var tongueHeight = this.style.mouthWidth * 0.8;

                // If not tongue - is white or black mouth
                if (this.style.tongueStyle) {
                    if(this.style.tongueY===null) this.style.tongueY = this.centre + (this.style.mouthHeight  * random(0.4, 0.7619)) - 8;
                    if(this.style.tongueHeight===null) this.style.tongueHeight = tongueHeight * 0.5;                
                } else {
                    if(this.style.tongueY===null) this.style.tongueY = this.centre + (this.style.mouthHeight  * random(0.4, 0.7619));
                    if(this.style.tongueHeight===null) this.style.tongueHeight = tongueHeight * random(0.9,1.3);
                }
            break;
            case 1 :
                if(this.style.chooseMouth===null) this.style.chooseMouth = 1; 
                // Dribble
                // Left or Right (50% chance)
                if(this.style.dribblePosition===null)this.style.dribblePosition = random(0,1,true);
            break;
            case 2 : 
                if(this.style.chooseMouth===null) this.style.chooseMouth = 1;
                //Tooth / Teeth
                // Left or Right (50% chance)
                if(this.style.toothPosition===null)this.style.toothPosition = random(0,1,true);

                // Both Teeth (33% chance)
                if(this.style.bothTeeth===null)this.style.bothTeeth = random(0,2,true);
            break;
            case 3 :
                // Alternate mouth, no embelishment 
                if(this.style.chooseMouth===null) this.style.chooseMouth = random([0,1,2,3]);
                if (!this.style.gender) {
                    // remove lips if male
                    this.style.chooseMouth = random([0,1,3]);
                }

            break;
            default : 

        }

   
        // Nose
        if(this.style.noseSelector===null)this.style.noseSelector = random(0,3,true);
        switch(this.style.noseSelector) {
            case 3 :
                // heart nose (25% chance)
                if(this.style.noseWidth===null)this.style.noseWidth = random(30,60);
            break;
            default :
                // default nose (75% chance)
                if(this.style.noseWidth===null)this.style.noseWidth = random(this.style.bodyWidth * 0.3, this.style.bodyWidth * 0.6);

        }


        // Eyes
        if(this.style.eyeCenterY===null)this.style.eyeCenterY = random(this.centre * 0.5, this.centre);
        if(this.style.eyeRadius===null)this.style.eyeRadius = random(40 * 0.8, 40 * 2.5);
        if(this.style.eyeWidth===null)this.style.eyeWidth = random(
            this.style.bodyWidth * 0.8,
            this.centre - this.style.eyeRadius * 0.6
        );
        if(this.style.eyeDirectionX===null)this.style.eyeDirectionX = random(-8, 8);
        if(this.style.eyeDirectionY===null)this.style.eyeDirectionY = random(-8, 8);

        



    }

    renderBody() {

      // main body
      this.svg
        .path(
          "M333.3,284.6c0,50.9-38.1,54.4-84.4,54.4s-83.2-3.6-83.2-54.4s37.5-92.1,83.8-92.1S333.3,233.7,333.3,284.6z"
        )
        .height(this.style.bodyHeight)
        .width(this.style.bodyWidth)
        .cx(this.centre)
        .cy(this.centre)
        .fill(this.fill)
        .stroke("#000")
        .attr({
          "stroke-width": 6
        });

        
  

      switch(this.style.mouthSelector) {
        case 0 :
            // tongue

           


            this.svg
                .path("M250.7,270.3c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4,0-0.7,0c-2.5,10.5-11.6,18.4-22.6,19.1c-0.1,0.7-0.1,1.5-0.1,2.2 c0,11.8,10.5,30.3,23.4,30.3s23.4-18.5,23.4-30.3c0-0.8,0-1.5-0.1-2.2C262.2,288.7,253.1,280.8,250.7,270.3z")
                .width(this.style.tongueWidth)
                .height(this.style.tongueHeight)
                .cx(this.centre)
                .cy(this.style.tongueY)
                .fill(!this.style.tongueStyle ? "#f00" : "#fff")
                .stroke("#000")
                .attr({
                "stroke-width": 6,
                "stroke-miterlimit": 10
                });
            
                if (!this.style.tongueStyle) {
                // tongue line
                this.svg
                    .line(this.centre, this.style.tongueY- (this.style.tongueHeight*0.5), this.centre,this.style.tongueY+ this.style.tongueHeight*0.3)
                    .stroke({ color: "#000", width: 6, linecap: "round", linejoin: "round" });

                }
          
        break;
        case 1 :
            // dribble

          
            this.style.positionX = (this.centre - (this.style.mouthWidth * 0.25));
            if (this.style.dribblePosition) {
                this.style.positionX = (this.centre + (this.style.mouthWidth * 0.25));
            }

           this.svg
                .path("M225,306.2c0-7.3-9-17.3-9-17.3s-9,10-9,17.3c0,7.3,4,9,9,9S225,313.4,225,306.2z")
                .cx(this.style.positionX)
                .cy(this.centre + this.style.mouthHeight + 2)
                .fill("#259DD8")
                .stroke("#000")
                .attr({
                    "stroke-width": 6,
                    "stroke-miterlimit": 10
                });
          break;
          case 2 :

            // tooth
              this.style.toothY = this.centre + (this.style.mouthHeight * 0.7619);
              this.style.toothY = this.style.toothY > 290 ? 290 : this.style.toothY;
        
            this.style.positionX = (this.centre - (this.style.mouthWidth * 0.25));
            if (this.style.toothPosition) {
                this.style.positionX = (this.centre + (this.style.mouthWidth * 0.25));
            }

           this.svg
                .polygon("250,305 239,285 250,285 261,285")
                .cx(this.style.positionX)
                .cy(this.style.toothY)
                .fill("#ffffff")
                .stroke("#000")
                .attr({
                    "stroke-width": 6,
                    "stroke-miterlimit": 10
                });


            if(!this.style.bothTeeth) {

                if (this.style.toothPosition) {
                    this.style.positionX = (this.centre - (this.style.mouthWidth * 0.25));
                }

                this.svg
                .polygon("250,305 239,285 250,285 261,285")
                .cx(this.style.positionX)
                .cy(this.style.toothY)
                .fill("#ffffff")
                .stroke("#000")
                .attr({
                    "stroke-width": 6,
                    "stroke-miterlimit": 10
                });
            } 

          break;          
      }


     // body mask
     this.svg
     .path(
       "M168,263c8.8-40.5,42-70.5,81.5-70.5c39.6,0,72.7,30,81.5,70.5L168,263z"
     )
     .height(this.style.bodyHeight * 0.5 + 7)
     .width(this.style.bodyWidth - 7)
     .cx(this.centre)
     .cy(this.centre - (this.style.bodyHeight * 0.25) + 7)
     .fill(this.fill);
    

    
        var mouthPath = [
            "M250,240.6v24c0,13.7-11.1,24.8-24.8,24.8 M250,264.6c0,13.7,11.1,24.8,24.8,24.8",
            "M250,240.6v24c0,13.7-11.1,24.8-24.8,24.8c-13.7,0-24.8-11.1-24.8-24.8 M250,264.6c0,13.7,11.1,24.8,24.8,24.8	c13.7,0,24.8-11.1,24.8-24.8",
            "M258,281c-3.1,0-5.8,0.9-8,2.5c-2.1-1.5-4.9-2.5-8-2.5c-6.7,0-17.3,6.8-14.9,10c2.9,4,5.9,7,11.9,10 c7.4,3.7,15.5,3.2,22,0c6-3,10-7,11.9-10C275,287.6,264.7,281,258,281z"
        ];

      switch(this.style.chooseMouth) {
            case 0:
            case 1:
            this.svg
                .path(mouthPath[this.style.chooseMouth])
                .width(this.style.mouthWidth)
                .height(this.style.mouthHeight)
                .cx(this.centre)
                .cy(this.centre + 10)
                .stroke({ color: "#000", width: 6, linecap: "round", linejoin: "round" })
                .fill(this.fill);
            break;
            case 2:
            this.svg
                .line(this.centre, this.centre + 20, this.centre, this.centre + 55)
                .cx(this.centre)
                .cy(this.centre)
                .stroke({ color: "#000", width: 6, linecap: "round", linejoin: "round" })
                .fill(this.fill);

            this.svg
                .path(mouthPath[this.style.chooseMouth])
                .width(this.style.bodyWidth * 0.4)
                .height(30)
                .cx(this.centre)
                .cy(this.centre + 22)
                .stroke({ color: "#000", width: 6, linecap: "round", linejoin: "round" })
                .fill("#ff0099");

            this.svg
                .path("M260.1,289c-6.8,4.4-14.8,4.4-20.1,0")
                .width(this.style.bodyWidth * 0.3)
                .height(this.style.bodyWidth *0.05)
                .cx(this.centre)
                .cy(this.centre + 22) 
                .fill("#000000")
               


            break;
            default : 
            this.svg
                .line(this.centre, this.centre + 20, this.centre, this.centre + 55)
                .cx(this.centre)
                .cy(this.centre)
                .stroke({ color: "#000", width: 6, linecap: "round", linejoin: "round" })
                .fill(this.fill);


                // mouth
            this.svg
                .circle(this.style.mouthWidth * 0.3)
                .cx(this.centre)
                .cy(this.centre + 25)
                .fill("#fff")
                .stroke({ color: "#000", width: 6, linecap: "round", linejoin: "round" })
      }
   
    }

    renderNose() {


        
  
        switch(this.style.noseSelector) {
            case 3 :
                // heart nose
                this.svg
                .path("M250,229.6c0-6.1-5.6-9-11.9-7.7c-3.7,0.7-6.8,3.8-7.6,7.5c-0.8,3.9,0.6,7.4,3.2,9.7l12.7,12.7 c2.1,2.1,5.4,2.1,7.5,0l13.1-13.1l0,0c1.8-1.8,3-4.3,3-7.1c0-5.5-4.4-9.9-9.9-9.9C254.4,221.7,250,224.2,250,229.6z")
                .width(this.style.noseWidth)
                .cx(this.centre)
                .cy(this.centre - 15)
                .fill("#000");
          break 
          default:
                // default nose
                this.svg
                .path("M278,226c2,9-12.5,17.5-28,17.5s-31-7.5-28-17.5c4-13.5,22.7-17.5,28-17.5S275.2,213.1,278,226z")
                .width(this.style.noseWidth)
                .cx(this.centre)
                .cy(this.centre - 15)
                .fill("#000");
          break;

         

        }

        
    }


    renderEyes() {
      
  
      // left eye
      this.svg
        .circle(this.style.eyeRadius)
        .cx(this.centre - this.style.eyeWidth)
        .cy(this.style.eyeCenterY)
        .fill("#000")
        .stroke("#000");
  
      const leftEyeInner = this.svg
        .circle(this.style.eyeRadius / 3)
        .cx(this.centre - this.style.eyeWidth)
        .cy(this.style.eyeCenterY)
        .fill("#fff");
  
      // right eye
      this.svg
        .circle(this.style.eyeRadius)
        .cx(this.centre + this.style.eyeWidth)
        .cy(this.style.eyeCenterY)
        .fill("#000")
        .stroke("#000");
  
      const rightEyeInner = this.svg
        .circle(this.style.eyeRadius / 3)
        .cx(this.centre + this.style.eyeWidth)
        .cy(this.style.eyeCenterY)
        .fill("#fff");
  
      leftEyeInner.translate(this.style.eyeDirectionX, this.style.eyeDirectionY);
      rightEyeInner.translate(this.style.eyeDirectionX, this.style.eyeDirectionY);
    }

    renderAccessories() {
        
        var pattern;
        var accessoriesColour = this.style.accessoriesColour;
        switch (this.style.accessories) {
            case 0 :
                console.log(accessoriesColour);
                pattern = this.svg.pattern(20, 20, function(add) {
                    add.rect(20,20).fill(accessoriesColour)
                    add.rect(10,10).fill("#fff")
                    add.rect(10,10).fill("#fff").move(10,10)
                })
        
            break;
            case 1 :
                pattern = this.svg.pattern(28, 28, function(add) {
                    add.rect(28,28).fill(accessoriesColour)
                    add.ellipse(14,14).fill("#fff")
                    add.ellipse(14,14).fill("#fff").move(14,14)
                });
            break;
            case 2 :
                pattern = this.svg.pattern(20,20, function(add) {
                    add.rect(20,20).fill(accessoriesColour)
                    add.polygon("5.5,0.1 7.2,3.4 10.8,4 8.2,6.5 8.8,10.2 5.5,8.5 2.3,10.2 2.9,6.5 0.2,4 3.9,3.4").fill("#fff")
                    add.polygon("14.5,9.1 16.2,12.4 19.8,13 17.2,15.5 17.8,19.2 14.5,17.5 11.3,19.2 11.9,15.5 9.2,13 12.9,12.4").fill("#fff")
                    
                }).scale(2)
        
            break;
        }




        this.svg
            .path("M300.9,79.4c-0.6-4.3-3.4-7.9-7.3-9.5c-2.2-0.9-4.7-0.9-7-0.1l-39.9,14.3c-3-2.6-7-4.2-11.2-4.2 c-4.2,0-8,1.5-11,4l-42.2-14.3c-2.2-0.8-4.7-0.8-6.8,0.1c-3.9,1.6-6.8,5.2-7.3,9.5c-0.1,0.9,0,1.9,0.2,2.8l3.2,12.3 c0.3,1.3,0.3,2.7,0,4l-3.2,12.2c-0.2,0.9-0.3,1.9-0.2,2.8c0.6,4.5,3.8,8.3,8,9.8c1.9,0.7,4,0.6,6,0l40.9-12.8 c3.2,3.3,7.6,5.4,12.5,5.4c4.7,0,8.9-1.9,12-4.9l39.4,12.3c1.9,0.6,4,0.7,6,0c4.2-1.5,7.4-5.2,8-9.8c0.1-0.9,0-1.9-0.2-2.8 l-3.2-12.2c-0.3-1.3-0.3-2.7,0-4l3.2-12.3C300.9,81.3,301,80.4,300.9,79.4z")
            .width(90)
            .height(100*0.4109)
            .cx(this.centre)
            .cy(100)
            .fill(this.style.accessoriesColour)
            .stroke({ color: "#000", width: 6, linecap: "round", linejoin: "round" })
            .rotate(this.style.accessoriesRotation)
            .fill(pattern)
        
        this.svg
            .circle(28)
            .cx(this.centre)
            .cy(100)
            .fill(this.style.accessoriesColour)
            .stroke({ color: "#000", width: 6, linecap: "round", linejoin: "round" })   
            .fill(pattern)
        
    }

    renderCheeks() {
        this.svg
            .circle(this.style.bodyWidth*.5)
            .cx(this.centre - (this.style.bodyWidth * 0.8))
            .cy(this.centre)
            .fill(this.fill)
            .opacity(0.5)

        this.svg
            .circle(this.style.bodyWidth*.5)
            .cx(this.centre + (this.style.bodyWidth * 0.8))
            .cy(this.centre)
            .fill(this.fill)
            .opacity(0.5)

    }

    renderBlush() {
        this.svg
            .ellipse(this.style.eyeRadius,this.style.eyeRadius*0.5)
            .cx(this.centre - this.style.eyeWidth)
            .cy(this.style.eyeCenterY + this.style.eyeRadius +5)
            .fill(this.fill)
            .opacity(0.2)

        this.svg
            .ellipse(this.style.eyeRadius,this.style.eyeRadius*0.5)
            .cx(this.centre + this.style.eyeWidth)
            .cy(this.style.eyeCenterY + this.style.eyeRadius +5)
            .fill(this.fill)
            .opacity(0.2)
    }
  
    createBackground() {
      const points = [
        { x: 0, y: 0 },
        { x: 500, y: 0 },
        { x: 500, y: 500 },
        { x: 0, y: 500 }
      ];
  
      this.svg
        .path(spline(points, 1, true))
        .cx(250)
        .cy(250)
        .fill("hsl(" + this.style.baseHue + ", " + this.style.backgroundSaturation + "%, " +  this.style.backgroundLightness + "%)");
    }

    render() {
      this.createBackground();
     // this.renderCheeks();
      this.renderEyes();
      if (this.style.gender) this.renderBlush();
      this.renderBody();
      this.renderNose();
      if (this.style.gender) this.renderAccessories();
    


    }
  }

  export default Character;