import React from "react";
import Character from './Character';
import FileSaver from "file-saver";

import './Generator.css';
import html2canvas from "html2canvas";
import CryptoJS from "crypto-js";
import PropTypes from 'prop-types';
import CallAPI  from "./../config/Connector";



function Generator() {

    const hashArray = [];
    let iterator = 0;

    let exampleLoader = {
        "gender": 1,
    }
    exampleLoader = null;



    function saveFile(_i) {
        const elem = document.getElementById("doggo-" + _i);
        //const bounds = elem.getBoundingClientRect();

        elem.style.width = 1024;
        elem.style.height = 1024;
        elem.setAttribute("width", 1024);
        elem.setAttribute("height", 1024);

        window.scrollTo(0,0);

        html2canvas(document.getElementById("doggo-box-" + _i), {
            width: 1024,
            height:1024,
            scale:2
        }).then((canvas) => {
            canvas.toBlob(function (blob) {
                elem.style.width = "100%";
                elem.style.height = "auto";
                elem.setAttribute("height","");
                elem.setAttribute("width","");
                FileSaver.saveAs(blob, elem.dataset.hash + ".png");

            });

        });

    }

    function saveAllFiles() {
        const doggos = document.querySelectorAll(".doggo-box");

        doggos.forEach(elem => {
            elem.querySelector("svg").style.width = 1024;
            elem.querySelector("svg").style.height = 1024;
            elem.querySelector("svg").setAttribute("width", 1024);
            elem.querySelector("svg").setAttribute("height", 1024);
    
            window.scrollTo(0,0);
    
            html2canvas(elem, {
                width: 1024,
                height:1024,
                scale:2
            }).then((canvas) => {
                canvas.toBlob(function (blob) {
                    elem.querySelector("svg").style.width = "100%";
                    elem.querySelector("svg").style.height = "auto";
                    elem.querySelector("svg").setAttribute("height","");
                    elem.querySelector("svg").setAttribute("width","");
                    FileSaver.saveAs(blob, elem.querySelector("svg").dataset.hash + ".png");
    
                });
    
            });
        })
    }



    function createCharacter(_i) {
        const wrapper = document.createElement("div");
        wrapper.id = "doggo-box-" + _i;
        wrapper.className = "doggo-box";
        const _character = new Character("doggo-" + _i, wrapper,exampleLoader);
        _character.render();
        console.log(_character.style);
        
        //console.log(Object.keys(_character.style).length);
        
        var hashObj = CryptoJS.SHA256(Object.values(_character.style).toString());
        var hash = hashObj.toString(CryptoJS.enc.Hex);
        const found = hashArray.find(element => element === hash);
        if (typeof found != "undefined") {
            console.log("Duplicate Found");
            //iterator--;
            //return true;
        }
        _character.svg.data('hash',hash);
        hashArray.push(hash);

        const btn = document.createElement("button");
        btn.addEventListener("click", () => {
            saveFile(_i);
        });
        btn.innerHTML = "Save";
        btn.className = "save-single";
        wrapper.appendChild(btn);


        return wrapper;

    }
      
    const dogList = document.createElement("div");
    dogList.className = "app";
    for (iterator = 0; iterator < 50; iterator++) {
        dogList.appendChild(createCharacter(iterator));
    }
  
     
     class DoggoList extends React.Component {
        render() {
            return <div ref={ref => ref.appendChild(this.props.pups)}></div>;
        }
    }


    DoggoList.propTypes = {
        pups : PropTypes.object
    }
    


    return (
        <div>
            <CallAPI />
            <button onClick={saveAllFiles}>Save All</button>
            <DoggoList pups={dogList} />
       </div>
        )


    
}


export default Generator;