class CharacterStyles {
    constructor(_obj = null) {



        this.gender = null;
        
        this.bodyWidth = null;
        this.bodyHeight = null;
        this.mouthWidth = null;
        this.mouthHeight = null;
    
        // Colours
        this.baseHue = null;
        this.baseSaturation = null;
        this.baseLightness = null;
        this.backgroundSaturation = null;
        this.backgroundLightness  = null;
    
    
        this.mouthSelector = null;
    
    
        // Mouth
        this.chooseMouth = null;
        this.positionX = null;
    
        // Tongue
        this.chooseTongue = null;
        this.tongueHeight = null;
        this.tongueWidth = null;
        this.tongueY = null;
        this.tongueStyle = null;
        
        // Dribble
        this.dribblePosition = null;
    
        // Teeth
        this.toothY = null;
        this.toothPosition = null;        
        this.bothTeeth = null;
    
        // Nose
        this.noseSelector = null;
        this.noseWidth = null;
    
    
        // Eyes
        this.eyeCenterY = null;
        this.eyeRadius = null;
        this.eyeWidth = null;
        this.eyeDirectionX = null;
        this.eyeDirectionY = null;

        // Accessories
        this.accessories = null;
        this.accessoriesColour = null;
        this.accessoriesRotation = null;

        if (_obj){
            _obj && Object.assign(this, _obj);
        }
    }
}

export default CharacterStyles;