//import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//import { Navigation, Footer, Home, Generator, About } from "./components";
import Generator from './components/Generator';

function App() {
  return (
    <div className="App">
        <Generator />
{/*
       <Router>
         <Navigation />
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/about" exact component={() => <About />} />
          <Route path="/app" exact component={() => <Generator />} />
        </Switch> 
        <Footer />
      </Router>*/}
    </div>
  );
}

export default App;
