import React, {Component} from 'react';

class CallAPI extends Component {
    constructor(props) {
        super(props);
        this.state = { apiRequest: ""}

    }

    callApi() {
        fetch("http://localhost:3002/testapi")
            .then(res => res.text())
            .then(res => this.setState({apiResponse : res}))
            .catch(err => err);
    }

    componentDidMount() {
        this.callApi();
    }

    render() {
        return (
            <p className="api-response">{this.state.apiResponse}</p>
        );
    }

}
export default CallAPI;